import { m } from 'framer-motion'
// next
import NextLink from 'next/link'
// @mui
import { styled } from '@mui/material/styles'
import { Button, Typography, Container } from '@mui/material'
// layouts
import Layout from '../layouts'
// components
import Page from '../components/Page'
import { MotionContainer, varBounce } from '../components/animate'
import useAuth from 'hooks/useAuth'
import { UserTypes } from '~/@types/profile'
import { PATH_DASHBOARD } from 'routes/paths'
import StateImage from 'components/state-image'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

Page404.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout variant="logoOnly">{page}</Layout>
}

// ----------------------------------------------------------------------

export default function Page404() {
  const { user } = useAuth()
  const dashboardUrl =
    user?.role === UserTypes.Creator
      ? PATH_DASHBOARD.creator_center.dashboard
      : PATH_DASHBOARD.general.promoter_program.about

  return (
    <Page title="404 Page Not Found">
      <Container component={MotionContainer}>
        <ContentStyle
          sx={{ textAlign: 'center', alignItems: 'center' }}
        >
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for.
              Perhaps you’ve mistyped the URL? Be sure to check your
              spelling.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <StateImage
              illustrationName="illustration_404"
              type="illustration"
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
          </m.div>

          <NextLink href={dashboardUrl} passHref>
            <Button size="large" variant="contained">
              Go to Home
            </Button>
          </NextLink>
        </ContentStyle>
      </Container>
    </Page>
  )
}
